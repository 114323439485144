import React from 'react'
import { Link } from 'gatsby'

const BlogCard = ({ item, text, index, slug, setter }) => {
  let tags = []
  if (item.tags) {
    tags = item.tags.split(',')
  }
  return (
    <li
      key={`${index}-blog`}
      className={index % 2 === 0 ? 'wrapper style2' : 'wrapper style1'}
    >
      <h2 className="blogTitle hs h2"> {item.title} </h2>
      <p className="blog-description p" style={{ marginBottom: 8 }}>
        {item.description}
      </p>
      {tags &&
        tags.map((tag, index) => (
          <a
            className={`blog-tag ${index > 0 ? `second-tag` : null}`}
            onClick={() => setter(tag)}
            key={`tag-${tag}`}
          >
            {tag}
          </a>
        ))}
      <Link
        to={`/posts/${slug}`}
        className="button"
        aria-label={`Read more about ${item.title}`}
      >
        {' '}
        {text}{' '}
      </Link>
    </li>
  )
}

export default BlogCard
