import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import LinkCard from '../components/LinkCard'
import BlogCard from '../components/BlogCard'

const BlogPage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH BLOG'

  let allPosts = [...data.allMdx.nodes, ...data.allPostsYaml.edges].map(
    item => {
      if ('id' in item) {
        return {
          title: item.frontmatter.title,
          description: item.frontmatter.description,
          slug: item.slug,
          tags: item.frontmatter.tags,
        }
      } else {
        return {
          title: item.node.title,
          description: item.node.description,
          url: item.node.url,
          tags: item.node.tags,
        }
      }
    }
  )

  const [fuse, setFuse] = useState(null)
  const [filter, setFilter] = useState('')
  const [filteredPosts, setFilteredPosts] = useState(allPosts)
  const searchInput = useRef(null)

  const handleSearch = event => {
    setFilter(event.target.value)
  }

  useEffect(() => {
    import(`fuse.js`).then(({ default: Fuse }) => {
      setFuse(
        new Fuse(allPosts, {
          keys: ['title', 'description', 'tags'],
          isCaseSensitive: false,
          ignoreLocation: true,
          threshold: 0.1,
        })
      )
    })
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (filter.length) {
        const search = fuse.search(filter)

        const results = search.map(result => result.item)
        setFilteredPosts(results)
        searchInput.current.focus()
      } else {
        setFilteredPosts(allPosts)
        setFilter(``)
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [filter, setFilteredPosts, fuse])

  return (
    <Layout>
      <Helmet>
        <title> {pageTitle}</title>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Laurie On Tech Blog"
          href="/rss.xml"
        />
      </Helmet>
      <section id="banner">
        <div id="banner" className="inner">
          <header>
            <h2 className="hs h2">BLOG</h2>
          </header>
        </div>
      </section>
      <section id="about" className="main style1">
        <section className="wrapper style4 container">
          <label className="search-label" htmlFor="search">
            Search:
          </label>
          <input
            ref={searchInput}
            className="search-box"
            value={filter}
            type="text"
            id="search"
            onChange={handleSearch}
          />
          <span className="blog-count">{filteredPosts.length}</span>
          <ul className="posts no-bullets ul" key="posts">
            {filteredPosts.length ? (
              filteredPosts.map((node, index) =>
                node.slug ? (
                  <BlogCard
                    item={node}
                    index={index}
                    text="Read More"
                    key={`${index}-blog`}
                    slug={node.slug}
                    setter={setFilter}
                  />
                ) : (
                  <LinkCard
                    item={node}
                    index={index}
                    text="Read More"
                    key={`${index}-link`}
                    setter={setFilter}
                  />
                )
              )
            ) : (
              <div className="empty-search">
                <span role="img" aria-label="star">
                  😩
                </span>{' '}
                Oh no! Nothing here! Guess I'll have to write about it soon.
              </div>
            )}
          </ul>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allPostsYaml {
      edges {
        node {
          title
          description
          url
          tags
        }
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 1000) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          tags
        }
        slug
      }
    }
  }
`

export default BlogPage
